
import { Component, Prop } from 'vue-property-decorator';
import Project from '@/models/Project';
import OpenMultipositionPopup from '@/components/views/NewProjectView/Offer/OpenMultipositionPopup.vue';
import { showErrorNotifications } from '@/helpers/NotificationHelper';
import { sortOfferItemsAndTitlesByRowNumber } from '@/helpers/OfferItemTableHelper';
import EnableEditMode from '@/components/views/NewProjectView/Offer/EnableEditMode.vue';
import OfferItemsTable from '@/components/views/project/OfferItemsTable.vue';
import { updateRowNumbers } from '@/helpers/NewProject/ProductsContainerActionsHelper';
import DeleteOfferItems from '@/components/views/NewProjectView/Offer/DeleteOfferItems.vue';
import { TransformedTableOfferItem } from '@/interfaces/components/ProductsInOffer/TransformedTableOfferItem';
import DeleteOfferItemPriceManipulations from '@/components/views/NewProjectView/Offer/DeleteOfferItemPriceManipulations.vue';
import HandleOfferItemDuplication from '@/components/views/NewProjectView/Offer/HandleOfferItemDuplication.vue';
import OpenOfferItemPriceManipulationModal from '@/components/views/NewProjectView/Offer/OpenOfferItemPriceManipulationModal.vue';
import AddOfferTitles from '@/components/views/NewProjectView/Offer/AddOfferTitles.vue';
import { Button } from 'ant-design-vue';
import Offer from '@/models/Offer';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import { AxiosError } from 'axios';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import ToggleAllowErrors from '@/components/views/NewProjectView/Offer/ToggleAllowErrors.vue';
import ToggleDisallowErrors from '@/components/views/NewProjectView/Offer/ToggleDisallowErrors.vue';
import OfferItem from '@/models/OfferItem';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';

@Component({
    name: 'ProductsActions',
    components: {
        UserRightsMixin,
        AddOfferTitles,
        OpenOfferItemPriceManipulationModal,
        HandleOfferItemDuplication,
        DeleteOfferItemPriceManipulations,
        DeleteOfferItems,
        EnableEditMode,
        OpenMultipositionPopup,
        Button,
        ToggleAllowErrors,
        ToggleDisallowErrors,
    },
})
export default class ProductsActions extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ required: true }) private offerItemsTableReference!: OfferItemsTable;
    @Prop({ default: false }) private isEditMode!: boolean;
    @Prop({ required: true }) private toggleEditing!: () => void;
    @Prop({ required: true }) private onToggleLoading!: () => void;
    @Prop({ default: () => [] }) private selectedOfferItems!: TransformedTableOfferItem[];
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private selectedOffer!: Offer | null;
    @Prop({ required: true }) private offerItemsAndOfferTitles!: any;

    private loadingOverlay = new LoadingOverlayHelper(this, {});

    public get isProjectLocked() {
        if (this.project == null) {
            return false;
        }

        return this.project.state === ProjectStates.ORDER;
    }

    public get isOfferLocked() {
        if (this.selectedOffer == null) {
            return false;
        }

        return this.selectedOffer.offerPdf !== null;
    }

    private get clientsPaymentTypeId() {
        if (this.project == null || this.project.client == null || this.project.client.paymentType == null) {
            return null;
        }

        return this.project.client.paymentType.id;
    }

    private get client() {
        if (this.project == null) {
            return null;
        }
        return this.project.client;
    }

    private get offerItems() {
        if (this.selectedOffer == null) {
            return [];
        }

        return this.selectedOffer.offerItems;
    }

    private get userCanAllowErrors() {
        return this.$store.getters['configurator/userCanAllowErrors'];
    }

    private onCancelChanges() {
        this.toggleEditing();
        this.offerItemsTableReference.onResetOfferItemsState();
    }

    private showChangesConfirm() {
        this.$confirm({
            title: this.$t('Jeste li sigurni da želite primjeniti ove promjene?') as string,
            content: '',
            okText: this.$t('Da') as string,
            okType: 'danger',
            cancelText: this.$t('Ne') as string,
            class: 'c-delete-modal',
            onOk: () => {
                this.onSaveChanges();
            },
            onCancel: () => {
                this.toggleEditing();
            },
        });
    }

    private async onSaveChanges() {
        if (this.selectedOffer == null) {
            return;
        }

        try {
            await updateRowNumbers(this.offerItemsTableReference, this.selectedOffer.id);
        } catch (e) {
            showErrorNotifications(e as AxiosError<any>);
            return;
        }

        this.toggleEditing();
    }

    private async onToggleErrorsOnOffer(shouldAllowErrors: boolean) {
        if (this.selectedOffer == null) {
            return;
        }

        let promises: any = [];

        for (let i = 0; i < this.selectedOfferItems.length; i++) {
            const offerItem = this.selectedOfferItems[i];
            const promise = OfferItem.updateAllowErrorState(offerItem.id, shouldAllowErrors, offerItem.productFormId);

            promises.push(
                promise.then(() => {
                    this.$set(offerItem, 'allowErrors', shouldAllowErrors);
                })
            );
        }

        try {
            this.loadingOverlay.start();
            await Promise.all(promises);
        } catch {
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: this.$t('Error updating allow error states!'),
            });
            Promise.reject();
        } finally {
            this.loadingOverlay.stop();
        }

        await this.$store.dispatch('configurator/updateOfferErrorState', this.selectedOffer.offerItems);
        EventBus.$emit(EventBusEvents.fetchSelectedOfferFromRepository);
        this.toggleEditing();
    }

    private created() {
        EventBus.$on(EventBusEvents.toggleEditingForProductsInOffer, this.toggleEditing);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.toggleEditingForProductsInOffer, this.toggleEditing);
    }
}
