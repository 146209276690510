
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Project from '@/models/Project';
import TwoRowWithTag from '@/components/global/TwoRowWithTag.vue';
import { generateOfferDescription } from '@/helpers/OfferHistoryTableHelper';
import Offer from '@/models/Offer';
import { OfferStates } from '@/enums/global/OfferStates';
import { Button } from 'ant-design-vue';
import { UserRepository } from '@/repositories/UserRepository';
import CreateOfferButton from '@/components/views/project/CreateOfferButton.vue';
import CreateOrderButton from '@/components/views/project/CreateOrderButton.vue';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import { EventBus } from '@/helpers/EventBusHelper';

@Component({
    name: 'OfferBottomBar',
    components: { TwoRowWithTag, Button, CreateOfferButton, CreateOrderButton },
})
export default class OfferBottomBar extends Vue {
    @Prop({ required: true }) private projectId!: string;
    @Prop({ required: true }) private project!: Project;
    @Prop({ required: true }) private selectedOffer!: Offer;
    @Prop({ required: true }) private lockEditing!: boolean;

    private get isSidebarCollapsed() {
        return this.$store.getters['generic/sidebarCollapsed'];
    }

    public get isProjectLocked() {
        if (this.project == null) {
            return false;
        }

        return this.project.state === ProjectStates.ORDER;
    }

    public get isProjectInRequestState() {
        if (this.project == null) {
            return false;
        }

        return this.project.state === ProjectStates.REQUEST;
    }

    public get isOfferLocked() {
        if (this.selectedOffer == null) {
            return false;
        }

        return this.selectedOffer.offerPdf !== null;
    }

    private get client() {
        return this.project.client;
    }

    private get currencySymbol() {
        return UserRepository.getCurrentUsersCurrencySymbol();
    }

    private get description() {
        return generateOfferDescription(this.selectedOffer) || '-';
    }

    private get offerHasErrors() {
        const offerErrorState = this.$store.getters['configurator/offerErrorState'];

        return offerErrorState.existingError;
    }

    private get price() {
        return this.selectedOffer.state === OfferStates.IMPORTED && this.selectedOffer.importedPrice
            ? this.selectedOffer.importedPrice
            : this.selectedOffer?.sellingOfferPrice?.priceWithTax;
    }
}
