
import { Component, Prop, Watch } from 'vue-property-decorator';
import Project from '@/models/Project';
import OfferHistoryNew from '@/components/views/project/OfferHistoryNew.vue';
import ProjectShellComponent from '@/components/views/NewProjectView/ProjectShellComponent.vue';
import OfferRepository from '@/repositories/OfferRepository';
import { setFirstProjectOfferAsSelectedIfNoneIsSelected } from '@/helpers/NewProject/NewProjectHelper';
import { Action } from 'vuex-class';
import ProductsContainer from '@/components/views/NewProjectView/Offer/ProductsContainer.vue';
import InstallationAndDeliveryDetailsAndForm from '@/components/views/NewProjectView/Offer/InstallationAndDeliveryDetailsAndForm.vue';
import ClearMontage from '@/components/views/NewProjectView/Offer/ClearMontage.vue';
import OrderNotesDetailsAndForm from '@/components/views/NewProjectView/Offer/OrderNotesDetailsAndForm.vue';
import PaymentDetailsAndForm from '@/components/views/NewProjectView/Offer/PaymentDetailsAndForm.vue';
import OfferBottomBar from '@/components/views/NewProjectView/Offer/OfferBottomBar.vue';
import { ProjectTabValues } from '@/enums/components/Project/ProjectTabValues';
import Offer from '@/models/Offer';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { ProjectStates } from '@/enums/components/Project/ProjectStates';
import { UserRightsMixin } from '@/mixins/UserRights';
import { mixins } from 'vue-class-component';
import CelebrationModal from '@/components/global/popup/CelebrationModal.vue';
import { debounce } from 'vue-debounce';
import { sortOfferItemsAndTitlesByRowNumber } from '@/helpers/OfferItemTableHelper';
import { mapAllOfferItemsForUpdatingRowNumbers } from '@/helpers/OfferItemTableHelper';
import { updateRowNumbersForOfferItemsAndTitles } from '@/helpers/OfferItemTableHelper';

@Component({
    name: 'ProjectTabOffers',
    components: {
        OfferBottomBar,
        PaymentDetailsAndForm,
        OrderNotesDetailsAndForm,
        ClearMontage,
        ProductsContainer,
        ProjectShellComponent,
        OfferHistoryNew,
        InstallationAndDeliveryDetailsAndForm,
        CelebrationModal,
    },
})
export default class ProjectTabOffers extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Action('projectLoadingState/updateAreOffersLoading')
    private updateAreOffersLoading!: (newState: boolean) => void;
    @Prop({ required: true }) private projectId!: string;
    @Prop({ default: [] }) private loadedKeys!: Set<ProjectTabValues>;
    @Prop({ required: true }) private project!: Project | null;
    @Prop({ required: true }) private selectedOffer!: Offer | null;
    @Prop({ required: true }) private lockEditing!: boolean;
    private isRecalculationModalVisible = false;
    private isOfferActionsPopup = false;
    private editState = false;
    private loadingState = false;

    public get isProjectLocked() {
        if (this.project == null) {
            return false;
        }
        return this.project.state === ProjectStates.ORDER || this.lockEditing;
    }

    public get isOfferLocked() {
        if (this.selectedOffer == null) {
            return false;
        }

        return this.selectedOffer.offerPdf !== null || this.lockEditing;
    }

    public get disabledActions() {
        return this.isProjectLocked || this.isOfferLocked;
    }

    private get offerList() {
        return OfferRepository.getProjectOffers(this.projectId);
    }

    private get contactPerson() {
        if (this.project == null) {
            return null;
        }
        return this.project.contactPerson;
    }

    private get hasDiscount() {
        if (!this.project) {
            return false;
        }

        return !this.project.roltekHelp;
    }

    private get offerItemsHaveInvalidPrice() {
        return this.$store.getters['configurator/offerPricesHaveErrors'];
    }

    private get offerItemsAndOfferTitles() {
        return sortOfferItemsAndTitlesByRowNumber(this.selectedOffer);
    }

    private async onUpdateProject(): Promise<void> {
        this.$emit('update:isTabLoading', true);
        try {
            this.$emit('update:loadedKeys', new Set([...this.loadedKeys, ProjectTabValues.Products]));
        } finally {
            // todo - temporary and should be removed when we switch to the new design
            this.updateAreOffersLoading(false);
            this.$emit('update:isTabLoading', false);
        }
    }

    private async installationAndDeliverySaveForm() {
        const installationAndDeliveryDetailsAndForm = this.$refs
            .installationAndDeliveryDetailsAndForm as InstallationAndDeliveryDetailsAndForm;

        await installationAndDeliveryDetailsAndForm.saveForm();
    }

    private async orderNotesSaveForm() {
        const orderNotesDetailsAndForm = this.$refs.orderNotesDetailsAndForm as OrderNotesDetailsAndForm;

        await orderNotesDetailsAndForm.saveForm();
    }

    private async paymentDetailsSaveForm() {
        const paymentDetailsAndForm = this.$refs.paymentDetailsAndForm as PaymentDetailsAndForm;

        await paymentDetailsAndForm.saveForm();
    }
    private closeRecalculationModal() {
        this.isRecalculationModalVisible = false;
    }

    private openRecalculationModal(isOfferActionsPopup: boolean = false) {
        this.isRecalculationModalVisible = true;
        this.isOfferActionsPopup = isOfferActionsPopup;
    }

    private debouncedupdateErrorState = debounce(() => {
        this.updateErrorState();
    }, 500);

    private async updateErrorState() {
        if (!this.selectedOffer?.offerItems.length) {
            return;
        }

        this.$store.dispatch('configurator/updateOfferErrorState', this.selectedOffer.offerItems);
        await this.$nextTick();
        if (this.offerItemsHaveInvalidPrice) {
            this.$notification.error({
                message: this.$t('One or more offer items have invalid prices'),
                description: this.$t('Please contact Roltek Control before proceeding with the order.'),
                duration: 5,
            });
        }
    }

    private async updateRowNumbers() {
        // Update wrong row numbers regardless if the project is locked
        if (!this.selectedOffer) {
            return;
        }

        await this.$nextTick();
        const rowNumbers = mapAllOfferItemsForUpdatingRowNumbers(this.offerItemsAndOfferTitles);

        try {
            await updateRowNumbersForOfferItemsAndTitles(rowNumbers);
            this.$store.dispatch('configurator/updateRowNumbers', rowNumbers);
            // Fetch again to update row numbers
            await Offer.getForOffersTab(this.selectedOffer.id);
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error updating row numbers') as string,
                description: '',
            });
        }
    }

    private checkIfRowNumbersAreCorrect() {
        if (!this.selectedOffer) {
            return;
        }

        const rowNumbersAreCorrect = this.offerItemsAndOfferTitles.every((offerItem: any, index: number) => {
            if (index === 0) {
                return true;
            }

            return offerItem.rowNumber === this.offerItemsAndOfferTitles[index - 1].rowNumber + 1;
        });

        if (rowNumbersAreCorrect) {
            return;
        }

        this.updateRowNumbers();
    }

    private async created() {
        if (this.loadedKeys.has(ProjectTabValues.Products)) {
            return;
        }
        this.$emit('update:isTabLoading', true);
        try {
            await setFirstProjectOfferAsSelectedIfNoneIsSelected(this.projectId);
            this.$emit('update:loadedKeys', new Set([...this.loadedKeys, ProjectTabValues.Products]));
        } finally {
            // todo - temporary and should be removed when we switch to the new design
            this.updateAreOffersLoading(false);
            this.$emit('update:isTabLoading', false);

            if (this.$route.query.initialTab !== '2') {
                await this.$nextTick();
                this.checkIfRowNumbersAreCorrect();
            }
        }

        EventBus.$on(EventBusEvents.openRecalculationPopup, this.openRecalculationModal);
        EventBus.$on(EventBusEvents.closeRecalculationPopup, this.closeRecalculationModal);
        EventBus.$on(EventBusEvents.updateRowNumbers, this.updateRowNumbers);
        EventBus.$on(EventBusEvents.updateProject, this.onUpdateProject);
        EventBus.$on(EventBusEvents.checkRowNumbers, this.checkIfRowNumbersAreCorrect);
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.openRecalculationPopup, this.openRecalculationModal);
        EventBus.$off(EventBusEvents.closeRecalculationPopup, this.closeRecalculationModal);
        EventBus.$on(EventBusEvents.updateRowNumbers, this.updateRowNumbers);
        EventBus.$off(EventBusEvents.updateProject, this.onUpdateProject);
        EventBus.$off(EventBusEvents.checkRowNumbers, this.checkIfRowNumbersAreCorrect);
    }

    @Watch('selectedOffer', { immediate: false })
    public handleSelectedOfferChange(val: Offer | null) {
        if (val) {
            this.debouncedupdateErrorState();
        }
    }
}
